import {
	Card,
	CardContent,
	CardMedia,
	Fade,
	Grid,
	Typography,
} from '@mui/material';
import { getImageSource, handleImgToDisplay } from '../../utils/globals';
import PropTypes from 'prop-types';
import { ASSET_TYPES, IMAGE_PREFIX } from '../../utils/enums';
import { useEffect, useState } from 'react';
import { getTextColor } from '../../utils/calculations';

const AssetsDisplayCard = ({ asset, setCurrentAsset }) => {
	const [isHover, setIsHover] = useState(false);
	const [imageSource, setImageSource] = useState(null);

	const handleSetImageSource = async () => {
		setImageSource(await handleImgToDisplay(asset, IMAGE_PREFIX.CARD));
	};

	useEffect(() => {
		if (asset && asset?.type !== ASSET_TYPES.COLOR) {
			handleSetImageSource();
		}
	}, [asset]);

	return (
		<Card
			key={asset?.id}
			elevation={0}
			sx={{
				width: '370px',
				boxShadow: '0px 0px 30px 0px #0000000D',
				minHeight: 'min(30vmin,290px)',
				borderRadius: '16px',
				cursor: 'pointer',
			}}
			onClick={() => {
				setCurrentAsset(asset);
			}}
			onMouseOver={() => {
				setIsHover(true);
			}}
			onMouseOut={() => {
				setIsHover(false);
			}}
		>
			<CardContent
				sx={{
					p: 0,
					position: 'relative',
					height: 230,
					background:
						asset?.type === ASSET_TYPES.COLOR
							? '#' + asset.localPath
							: 'transparent',
					overflow: 'hidden',
				}}
			>
				{asset?.type !== ASSET_TYPES.COLOR && (
					<CardMedia
						component='img'
						height='230'
						image={
							imageSource && imageSource?.src.replace(/\\/g, '/')
						}
						alt={asset?.name}
						sx={{ width: 370, height: '100%' }}
					/>
				)}
				{isHover && (asset?.excerpt || asset?.description) && (
					<Fade in={isHover}>
						<Grid
							container
							alignItems={'center'}
							sx={{
								position: 'absolute',
								inset: 0,
								background:
									asset?.type === ASSET_TYPES.COLOR
										? ''
										: 'rgba(239, 238, 238, 0.9)',
								color:
									asset?.type === ASSET_TYPES.COLOR
										? 'background.paper'
										: 'inherit',
							}}
							height='230'
							paddingInline={5}
						>
							{asset?.type === ASSET_TYPES.COLOR ? (
								<Grid
									container
									flexDirection={'column'}
									gap={1}
								>
									{JSON.parse([asset?.description]).map(
										(color, index) => (
											<Typography
												key={index}
												fontWeight={300}
												color={getTextColor(
													'#' + asset?.localPath,
												)}
												display={'flex'}
												gap={1}
											>
												<p>{color?.name}</p>
												<p>{color?.value}</p>
											</Typography>
										),
									)}
								</Grid>
							) : (
								<div
									dangerouslySetInnerHTML={{
										__html: asset?.excerpt,
									}}
								/>
							)}
						</Grid>
					</Fade>
				)}
			</CardContent>

			<CardContent>
				<Typography
					sx={{
						outline: 'none !important',
						wordBreak: 'break-all',
						overflow: 'hidden',
						display: '-webkit-box',
						'-webkit-line-clamp': '1',
						lineClamp: '1',
						'-webkit-box-orient': 'vertical',
					}}
				>
					{asset?.name}
				</Typography>
			</CardContent>
		</Card>
	);
};

AssetsDisplayCard.propTypes = {
	asset: PropTypes.object,
	setCurrentAsset: PropTypes.func,
};
export default AssetsDisplayCard;
