import { useMutation } from '@tanstack/react-query';
import { ALERT_STATUS, useAlert } from '../../../contexts/AlertContext';

import { uploadAssetFile, uploadFilesWithProgress } from '../../files';

const useUploadAssetFile = (handleSuccess, onUploadProgress = null) => {
	const { handleAlert } = useAlert();

	const { mutateAsync: handleUploadAssetFile, isPending } = useMutation({
		mutationFn: (data) =>
			onUploadProgress
				? uploadFilesWithProgress(data, onUploadProgress)
				: uploadAssetFile(data),
		onSuccess: handleSuccess,
		onError: (error) => {
			handleAlert(ALERT_STATUS.ERROR, error?.message);
		},
		onUploadProgress,
	});

	return { handleUploadAssetFile, isPending };
};

export default useUploadAssetFile;
