import { Box, Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import palette from '../../styles/palette';
import { formatSpacingValues, renderComponent } from '../../utils/fields';
import AddBlock from '../Inputs/AddBlock';
import BlockActions from '../Inputs/BlockActions';
import { useFields } from '../../contexts/FieldsContext';

const INITIAL_TOTAL = 2;

const ColumnsField = ({
	width,
	index,
	setIsChoosingAsset,

	obj,
}) => {
	const { isEditing, isCreating, setIsCreating, setIsEditing } =
		useBrandbooksContext();
	const {
		currentField,
		handleSaveField,
		handleEditField,
		deletedFields,
		updatedFields,
	} = useFields();

	const [totalColumns, setTotalColumns] = useState(INITIAL_TOTAL);
	const [initialWidth, setInitialWidth] = useState(width);

	const [columnContent, setColumnContent] = useState([]);

	const field = currentField?.index === index ? currentField : obj;

	const isDeleted = (item) => {
		return deletedFields.find((f) => f === item?.id);
	};

	const handleGetUpdatedField = (index) => {
		return updatedFields.find((field) => field?.index === index);
	};

	const renderColumns = () => {
		return Array.from({ length: totalColumns }, (_, key) => {
			// if (isDeleted(columnContent[key])) columnContent[key] = null;
			const updatedField = handleGetUpdatedField(
				columnContent[key]?.index,
			);

			const objToRender = updatedField ?? columnContent[key];
			return (
				<Grid
					item
					key={key}
					sx={{
						border: '2px dashed',
						borderColor:
							currentField?.index === index
								? palette.info.dark
								: 'transparent',
					}}
					xs={initialWidth[key]}
				>
					{columnContent.length && objToRender ? (
						renderComponent(objToRender, setIsChoosingAsset)
					) : (
						<AddBlock
							isInColumn={true}
							columnFieldIndex={index}
							positionInColumn={key}
						/>
					)}
				</Grid>
			);
		});
	};

	useEffect(() => {
		setInitialWidth(field?.width);
		setTotalColumns(field?.total);
		setColumnContent(field?.value);
		console.log('field', field);
	}, [currentField]);

	return (
		<BlockActions
			index={index}
			isEditing={
				(isCreating && currentField?.index === index) ||
				(isEditing && currentField?.index === index)
			}
			saveAction={() => {
				handleSaveField(currentField);
				setIsCreating(false);
				setIsEditing(false);
			}}
			editAction={() => {
				handleEditField(obj);
				setIsEditing(true);
			}}
			field={obj}
		>
			<Box m={1} py={3} sx={{ position: 'relative' }}>
				<Grid
					container
					sx={{
						margin: formatSpacingValues(field?.margin),
						padding: formatSpacingValues(field?.padding),
					}}
					wrap='nowrap'
				>
					{renderColumns().map((col, index) => (
						<>
							{col}
							{(index !== 0 ||
								index !== renderColumns()?.length - 1) && (
								<Divider
									style={{
										width: 3,
									}}
								/>
							)}
						</>
					))}
				</Grid>
			</Box>
		</BlockActions>
	);
};
ColumnsField.propTypes = {
	innerComponents: PropTypes.array,
	width: PropTypes.array,
	value: PropTypes.array,
	margin: PropTypes.array,
	padding: PropTypes.array,
	total: PropTypes.number,
	index: PropTypes.number,
	setIsChoosingAsset: PropTypes.func,
	handleIndexingFields: PropTypes.func,
	obj: PropTypes.object,
};

export default ColumnsField;
