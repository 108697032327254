import { Box, Typography } from '@mui/material';
import ContentEditable from '../Inputs/ContentEditable';
import ReactQuill from 'react-quill';
import { useFileContext } from '../../contexts/FilesContext';

const toolbarOptions = [
	['bold', 'italic', 'underline'],
	['blockquote'],
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ header: [2, 3, false] }],
	[{ align: [] }],
];

const StepperDetails = () => {
	const { setCurrentFile, currentFile } = useFileContext();
	return (
		<Box pr={3} display={'flex'} flexDirection={'column'} gap={2}>
			<ContentEditable
				label='Name'
				type={'text'}
				value={currentFile?.name || currentFile?.asset?.name}
				canEdit={true}
				setValue={({ target: { value } }) => {
					setCurrentFile((prev) => ({
						...prev,
						name: value,
					}));
				}}
			/>

			<Typography fontWeight={500} mt={1}>
				Excerpt
			</Typography>
			<ReactQuill
				style={{ marginBottom: 30, minHeight: '15vh' }}
				theme='snow'
				value={currentFile?.excerpt}
				onChange={(value) => {
					setCurrentFile((prev) => ({
						...prev,
						excerpt: value,
					}));
				}}
				modules={{
					toolbar: toolbarOptions,
				}}
			/>
			<Typography fontWeight={500} mt={7}>
				Description
			</Typography>
			<ReactQuill
				style={{ minHeight: '15vh' }}
				theme='snow'
				value={currentFile?.description}
				onChange={(value) => {
					setCurrentFile((prev) => ({
						...prev,
						description: value,
					}));
				}}
				modules={{
					toolbar: toolbarOptions,
				}}
			/>
		</Box>
	);
};

export default StepperDetails;
