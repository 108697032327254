import {
	Grid,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography,
} from '@mui/material';
import CustomAvatar from './CustomAvatar';
import PropTypes from 'prop-types';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useState } from 'react';
import ClientCredsReset from '../Inputs/ClientCredsReset';

const ClientStrip = ({
	client,
	isAssignedList,
	handleEditItem,
	handleRemoveItem,
	handleSendMail,
}) => {
	const [isHover, setIsHover] = useState(false);

	return (
		<ListItem
			onMouseOver={() => {
				setIsHover(true);
			}}
			onMouseOut={() => {
				setIsHover(false);
			}}
			alignItems='center'
			secondaryAction={
				<Grid container gap={2}>
					{isHover && !isAssignedList && (
						<Tooltip title='Edit' placement='top' arrow>
							<IconButton
								edge='end'
								aria-label='edit'
								onClick={() => {
									handleEditItem && handleEditItem(client);
								}}
							>
								<ModeEditOutlineOutlinedIcon
									sx={{
										color: 'text.primary',
									}}
								/>
							</IconButton>
						</Tooltip>
					)}
					{!isAssignedList && <ClientCredsReset client={client} />}
					{isHover && handleSendMail && (
						<IconButton
							edge='end'
							aria-label='send-mail'
							onClick={handleSendMail}
						>
							<SendOutlinedIcon />
						</IconButton>
					)}
				</Grid>
			}
			sx={{
				cursor: 'pointer',
				'&:hover': { '* ': { fontWeight: 500 } },
				width: '100%',
			}}
		>
			{isAssignedList && handleRemoveItem && (
				<Tooltip title='Remove from brand' placement='left' arrow>
					<ListItemIcon
						edge='end'
						aria-label='remove'
						onClick={() => {
							handleRemoveItem && handleRemoveItem(client);
						}}
					>
						<ClearOutlinedIcon
							sx={{
								cursor: 'pointer',
							}}
						/>
					</ListItemIcon>
				</Tooltip>
			)}
			<ListItemAvatar>
				<CustomAvatar
					name={client?.name}
					image={client?.name}
					size={40}
				/>
			</ListItemAvatar>

			<ListItemText
				primary={client.name}
				secondary={
					<Typography
						sx={{
							display: 'block',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
						component='p'
						variant='body2'
						color='text.primary'
						display={'block'}
					>
						{client.email}
					</Typography>
				}
			/>
		</ListItem>
	);
};

ClientStrip.propTypes = {
	client: PropTypes.shape({
		name: PropTypes.string,
		email: PropTypes.string,
	}),
	isAssignedList: PropTypes.bool,
	handleEditItem: PropTypes.func,
	handleRemoveItem: PropTypes.func,
	handleSendMail: PropTypes.func,
};

export default ClientStrip;
