import { Grid, Typography } from '@mui/material';
import { useFileContext } from '../../contexts/FilesContext';
import FolderSystem from '../../components/Layouts/FolderSystem';
import FilesDisplay from '../../components/Layouts/FilesDisplay';
import AssetDrawer from '../../components/Drawers/AssetDrawer';
import { useCallback, useEffect, useState } from 'react';
import MultipleUploadDialog from '../../components/Dialogs/MultipleUploadDialog';
import FolderDialog from '../../components/Dialogs/FolderDialog';
import { useLocation } from 'react-router-dom';
const Files = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isMultipleUpload, setIsMultipleUpload] = useState(false);

	const [isFolderEdit, setIsFolderEdit] = useState(false);
	const {
		currentFile,
		handleSetBrand,
		brand,
		folders,
		navigateToFolder,
		setCurrentFile,
		files,
	} = useFileContext();
	const location = useLocation();

	const handleSetCurrentFormSearch = () => {
		if (location?.state?.tag === 'assets') {
			const current = folders.find((folder) =>
				folder?.folders.find(
					(f) => f?.id === location?.state?.current?.folderId,
				),
			);

			if (current) {
				console.log(folders, brand);
				handleSetBrand(current.brand?.id);
				navigateToFolder(location?.state?.current?.folderId);
				setCurrentFile(location?.state?.current);
			}
		}
	};

	useEffect(() => {
		if (
			location?.state?.brandId &&
			brand?.id !== location?.state?.brandId &&
			folders
		) {
			handleSetBrand(location?.state?.brandId);
		}

		if (location?.state && location?.state?.current && folders?.length) {
			handleSetCurrentFormSearch();
		}
	}, [location?.state, brand, folders]);

	useEffect(() => {
		if (currentFile != null) setIsDrawerOpen(true);
	}, [currentFile]);

	return (
		<>
			<Typography variant='h5' mt={3}>
				File Management
			</Typography>
			<Grid container sx={{ height: '98%' }} gap={3}>
				<Grid item xs={2} fullHeight>
					<FolderSystem setIsFolderEdit={setIsFolderEdit} />
				</Grid>
				<Grid item xs={9}>
					<FilesDisplay
						setIsDrawerOpen={setIsDrawerOpen}
						setIsMultipleUpload={setIsMultipleUpload}
						setIsFolderEdit={setIsFolderEdit}
					/>
				</Grid>
			</Grid>
			{isDrawerOpen && (
				<AssetDrawer
					isOpen={isDrawerOpen}
					setIsOpen={setIsDrawerOpen}
				/>
			)}
			<MultipleUploadDialog
				isOpen={isMultipleUpload}
				setIsOpen={setIsMultipleUpload}
			/>
			<FolderDialog isOpen={isFolderEdit} setIsOpen={setIsFolderEdit} />
		</>
	);
};

export default Files;
