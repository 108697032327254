import {
	Box,
	Grid,
	IconButton,
	ImageListItemBar,
	Skeleton,
} from '@mui/material';
import { StyledAssetCard } from '../../styles/layouts';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';
import { useFileContext } from '../../contexts/FilesContext';

import {
	getImageSource,
	handleGetThumbnailUrl,
	handleImgToDisplay,
} from '../../utils/globals';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { ASSET_TYPES, IMAGE_PREFIX } from '../../utils/enums';
import { useEffect, useState } from 'react';

const AssetCard = ({ asset, bulkActions, setSelected, isInList }) => {
	const { setCurrentFile, setIsCreating, setIsEditing } = useFileContext();
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [imageSource, setImageSource] = useState(null);

	const handleSetImageSource = async () => {
		setImageSource(await handleImgToDisplay(asset, IMAGE_PREFIX.THUMB));
	};

	useEffect(() => {
		handleSetImageSource();
	}, []);

	return (
		<StyledAssetCard
			bulkActions={bulkActions}
			isInList={isInList}
			needsUpload={asset?.type !== ASSET_TYPES.COLOR && !asset?.dbxPath}
		>
			<Grid
				onClick={() => {
					setIsCreating(false);
					setIsEditing(true);
					setCurrentFile(asset);
				}}
				sx={{ height: '100%' }}
				container
				justifyContent={'center'}
				alignItems={'center'}
			>
				{asset.type === ASSET_TYPES.COLOR ? (
					<Box
						sx={{
							background: `${'#' + asset.localPath}`,
							width: '100%',
							height: '100%',
						}}
					></Box>
				) : imageSource ? (
					<img
						{...imageSource}
						alt={asset.name}
						loading='lazy'
						style={{
							opacity: 0.7,
							height: '100%',
							width: '100%',
							objectFit: 'cover',
							filter: isImageLoaded ? 'blur(0)' : 'blur(10px)',
							transform: isImageLoaded
								? ' scale(1)'
								: 'scale(0.5)',
							transition: 'transform 0.5s ease, filter 0.5s ease',
						}}
						onLoad={() => {
							setIsImageLoaded(true);
						}}
					/>
				) : (
					<Skeleton
						variant='rounded'
						width={'100%'}
						height={'100%'}
					/>
				)}
			</Grid>
			<ImageListItemBar
				sx={{
					background: 'transparent',
				}}
				position='top'
				actionIcon={
					!bulkActions && (
						<IconButton
							sx={{
								color: 'background.grey',
							}}
							aria-label={`info about ${asset.name}`}
							onClick={() => {
								setIsCreating(false);
								setIsEditing(true);
								setCurrentFile(asset);
							}}
						>
							<InfoIcon />
						</IconButton>
					)
				}
				actionPosition='right'
			/>
			<ImageListItemBar
				actionIcon={
					asset?.type !== ASSET_TYPES.COLOR &&
					!asset?.dbxPath && (
						<WarningAmberOutlinedIcon color='warning' />
					)
				}
				actionPosition='left'
				sx={{
					background: 'transparent',
					padding: 1,
				}}
				position='top'
			/>
			{asset.hiddenFile && (
				<ImageListItemBar
					subtitle='Hidden'
					sx={{
						borderRadius: 'inherit',
						borderTopLeftRadius: 0,
						borderBottomRightRadius: 0,
						background: 'rgba(0, 0, 0, 0.3)',
						right: 'unset',
					}}
				/>
			)}
			{bulkActions && (
				<Grid
					container
					justifyContent={'end'}
					sx={{
						position: 'absolute',
						inset: 0,
						zIndex: 1,
						p: 1,
						background: isInList ? '#4a4a4a36' : 'none',
						color: 'text.secondary',
					}}
					onClick={() => {
						setSelected((prev) => {
							if (isInList) {
								return prev.filter(
									(item) => item?.id !== asset?.id,
								);
							} else {
								return [...prev, asset];
							}
						});
					}}
				>
					{isInList ? (
						<CheckBoxIcon />
					) : (
						<CheckBoxOutlineBlankOutlinedIcon />
					)}
				</Grid>
			)}
		</StyledAssetCard>
	);
};
AssetCard.propTypes = {
	asset: PropTypes.object,
	bulkActions: PropTypes.bool,
	isInList: PropTypes.bool,
	setSelected: PropTypes.func,
};
export default AssetCard;
