import { Chip, Grid, Link } from '@mui/material';
import palette from '../../styles/palette';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useFileContext } from '../../contexts/FilesContext';
import { useEffect, useState } from 'react';

import useGetCategories from '../../api/hooks/files/useGetCategories';
import CategoryDialog from '../Dialogs/CategoryDialog';

const StepperTags = () => {
	const { data: categories } = useGetCategories();
	const { setCurrentFile, currentFile } = useFileContext();
	const [allCategories, setAllCategories] = useState([]);
	const [isDialogOpen, setIsDialogOpen] = useState(null);
	const [selected, setSelected] = useState(
		new Set(
			currentFile?.CategoriesOnAssets?.length > 0
				? currentFile?.CategoriesOnAssets.map((cat) => cat?.categoryId)
				: [],
		),
	);

	function handleSelectionChanged(id) {
		const newSet = new Set(selected);
		if (newSet.has(id)) newSet.delete(id);
		else newSet.add(id);
		setSelected(newSet);
	}

	useEffect(() => {
		setCurrentFile((prev) => ({
			...prev,
			CategoriesOnAssets: [...selected],
		}));
	}, [selected]);

	useEffect(() => {
		if (categories !== null) setAllCategories(categories?.data);
	}, [categories]);

	return (
		<>
			<Grid
				container
				gap={1}
				sx={{ overflow: 'scroll' }}
				mt={2}
				alignItems={'center'}
			>
				{allCategories.length > 0 &&
					allCategories.map((c) => (
						<Chip
							icon={
								selected.has(c.id) ? (
									<CheckCircleOutlinedIcon
										sx={{
											fill: palette.text.link,
										}}
									/>
								) : (
									<CircleOutlinedIcon
										sx={{
											fill: palette.background.grey,
										}}
									/>
								)
							}
							key={c.id}
							onClick={() => handleSelectionChanged(c.id)}
							sx={{ width: 'max-content' }}
							variant='outlined'
							label={c?.name}
						/>
					))}
				<Link
					component={'p'}
					onClick={() => {
						setIsDialogOpen(true);
					}}
				>
					+ Add New Tag
				</Link>
			</Grid>

			{isDialogOpen && (
				<CategoryDialog
					isOpen={isDialogOpen}
					setIsOpen={setIsDialogOpen}
					setAllChips={setAllCategories}
				/>
			)}
		</>
	);
};

export default StepperTags;
