import {
	Box,
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grid,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import { useEffect, useRef, useState } from 'react';
import CustomAvatar from '../Cards/CustomAvatar';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import SideBarOptions from './SideBarOptions';
import placeHolderImage from '../../assets/images/placeholder.jpg';
import { capitalizeFirstLetter, getImageSource } from '../../utils/globals';
import useGetBrandClients from '../../api/hooks/brands/useGetBrandClients';
import ClientStrip from '../Cards/ClientStrip';
import { BRANDBOOK_STATUS } from '../../utils/enums';
import useUpdateStatus from '../../api/hooks/brandbooks/useUpdateStatus';
import GeneralButton from '../Inputs/GeneralButton';
import useSendLink from '../../api/hooks/brandbooks/useSendLink';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useFields } from '../../contexts/FieldsContext';

const STEP_TITLES = ['Brand details', 'Users', 'Customize'];

const BrandbookSidebar = () => {
	const { currentField } = useFields();
	const {
		currentBrandBook,
		setCurrentBrandBook,
		currentBrand,
		setBrandBooks,
	} = useBrandbooksContext();
	const { handleAlert } = useAlert();
	const [step, setStep] = useState(0);
	const [stepTitle, setStepTitle] = useState(STEP_TITLES[step]);
	const [status, setStatus] = useState(currentBrandBook?.status);
	const [buttonsOpen, setButtonsOpen] = useState(false);
	const anchorRef = useRef(null);

	const [selectedIndex, setSelectedIndex] = useState(
		Object.keys(BRANDBOOK_STATUS).findIndex(
			(item) => item === currentBrandBook?.status,
		),
	);

	const { data: brandClients } = useGetBrandClients(
		currentBrand?.id,
		!!currentBrand,
	);
	const { handleUpdateBrandBookStatus } = useUpdateStatus((data) => {
		setStatus(data?.data.status);
		setCurrentBrandBook((prev) => ({ ...prev, status: data?.data.status }));
		setBrandBooks((prev) =>
			prev.map((b) => {
				return b?.id === data?.data?.id
					? { ...b, status: data?.data.status }
					: b;
			}),
		);
		handleAlert(ALERT_STATUS.SUCCESS, data.message);
	});
	const { sendOutClientEmail } = useSendLink((data) => {
		handleAlert(ALERT_STATUS.SUCCESS, data.message);
	});

	const handleChange = (event, newValue) => {
		setStep(newValue);
		setStepTitle(STEP_TITLES[newValue]);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setButtonsOpen(false);
	};

	useEffect(() => {
		currentField &&
			handleChange(null, currentField?.type === 'BANNER' ? 0 : 2);
	}, [currentField]);

	useEffect(() => {
		setSelectedIndex(
			Object.keys(BRANDBOOK_STATUS).findIndex(
				(item) => item === currentBrandBook?.status,
			),
		);
	}, [currentBrandBook]);

	return (
		<Paper
			elevation={0}
			sx={{
				p: 2,
				width: '100%',
				height: 'fit-content',
				minHeight: '70vh',
				borderRadius: '32px',
				position: 'sticky',
				top: '2vmin',
			}}
		>
			<Grid
				container
				wrap='no-wrap'
				alignItems={'center'}
				minHeight={'70vh'}
				direction={'column'}
			>
				<Tabs value={step} onChange={handleChange}>
					<Tab
						label={STEP_TITLES[0]}
						icon={<MenuBookOutlinedIcon />}
						sx={{ fontSize: '70%' }}
					/>
					<Tab
						label={STEP_TITLES[1]}
						icon={<PeopleOutlineOutlinedIcon />}
						sx={{
							fontSize: '70%',
						}}
					/>
					<Tab
						label={STEP_TITLES[2]}
						icon={<GridViewOutlinedIcon />}
						sx={{
							fontSize: '70%',
							display: !currentField?.type ? 'none' : 'flex',
						}}
					/>
				</Tabs>
				<Box
					sx={{
						borderBottom: 1,
						borderColor: 'divider',
						display: 'flex',
						alignItems: 'center',
						mb: 2,
					}}
				></Box>
				{stepTitle === STEP_TITLES[0] && (
					<Grid
						container
						direction={'column'}
						gap={1}
						height={'85%'}
						alignItems={'center'}
					>
						<Box
							sx={{
								borderRadius: '8px',
								height: '150px',
								overflow: 'hidden',
								maxWidth: '95%',
								backgroundImage: `url(${placeHolderImage})`,
							}}
						>
							<img
								src={
									currentBrand?.featuredImage
										? getImageSource(
												currentBrand?.featuredImage,
										  )?.src
										: placeHolderImage
								}
								alt={currentBrand?.name}
								style={{ objectFit: 'cover', height: '100%' }}
							/>
						</Box>
						<CustomAvatar
							name={currentBrand?.name}
							image={getImageSource(currentBrand?.logo)?.src}
							size={80}
							sx={{
								border: '2px solid #fff',
								marginTop: '-5vmin',
							}}
						/>
						<Typography variant='h6'>
							{currentBrand?.name}
						</Typography>
						<Grid
							container
							gap={2}
							alignItems={'center'}
							justifyContent={'center'}
						>
							<Typography>Status</Typography>
							<ButtonGroup variant='outlined' ref={anchorRef}>
								<Button onClick={() => {}}>
									{capitalizeFirstLetter(
										selectedIndex !== -1
											? Object.keys(BRANDBOOK_STATUS)[
													selectedIndex
											  ]
											: BRANDBOOK_STATUS.DRAFT,
									)}
								</Button>
								<Button
									size='small'
									onClick={() => {
										setButtonsOpen((prev) => !prev);
									}}
								>
									<ArrowDropDownIcon />
								</Button>
							</ButtonGroup>
							<Popper
								sx={{
									zIndex: 1,
								}}
								open={buttonsOpen}
								anchorEl={anchorRef.current}
								role={undefined}
								transition
								disablePortal
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin:
												placement === 'bottom'
													? 'center top'
													: 'center bottom',
										}}
									>
										<Paper>
											<ClickAwayListener
												onClickAway={handleClose}
											>
												<MenuList
													id='split-button-menu'
													autoFocusItem
												>
													{Object.keys(
														BRANDBOOK_STATUS,
													)
														.filter(
															(item) =>
																item !==
																BRANDBOOK_STATUS.TEMPLATE,
														)
														.map(
															(status, index) => (
																<MenuItem
																	key={status}
																	selected={
																		index ===
																		selectedIndex
																	}
																	onClick={(
																		event,
																	) => {
																		setSelectedIndex(
																			index,
																		);
																		setButtonsOpen(
																			false,
																		);
																		handleUpdateBrandBookStatus(
																			{
																				status,
																				brandBook:
																					currentBrandBook?.id,
																			},
																		);
																	}}
																>
																	{capitalizeFirstLetter(
																		status,
																	)}
																</MenuItem>
															),
														)}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</Grid>
					</Grid>
				)}
				{stepTitle === STEP_TITLES[1] &&
					brandClients &&
					brandClients?.data.length > 0 && (
						<>
							{brandClients?.data.map((client) => (
								<ClientStrip
									client={client}
									index={client?.id}
									key={client?.id}
									isAssignedList={true}
									handleEditItem={false}
									handleRemoveItem={false}
									handleSendMail={() => {
										sendOutClientEmail({
											brandBook: currentBrandBook?.id,
											client: client?.id,
										});
									}}
								/>
							))}
							<GeneralButton
								icon={<SendOutlinedIcon />}
								onClick={() => {
									if (
										currentBrandBook.status ===
										BRANDBOOK_STATUS.DRAFT
									)
										return handleAlert(
											ALERT_STATUS.WARNING,
											'Must publish brand book first.',
										);
									sendOutClientEmail({
										brandBook: currentBrandBook?.id,
									});
								}}
								mt={'auto'}
							>
								Mail All Users
							</GeneralButton>
						</>
					)}
				<Grid container direction={'column'} gap={1} wrap='no-wrap'>
					{stepTitle === STEP_TITLES[2] && <SideBarOptions />}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default BrandbookSidebar;
