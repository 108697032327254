import { useRef } from 'react';
import { capitalizeFirstLetter } from '../../utils/globals';
import AssetsDisplayCard from './AssetsDisplayCard';
import { Box, Grid, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CountBadge } from '../../styles/layouts';

import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { getTotalFilesInFolder } from '../../utils/calculations';

function getFilesFromFolder(folder) {
	if (!folder) {
		return [];
	}

	const files = [];

	if (folder.Asset) {
		files.push(...folder.Asset);
	}

	if (folder.children) {
		folder.children.forEach((child) => {
			if (child && child.Asset) {
				files.push(...child.Asset);
			}
		});
	}

	return files;
}

const AssetsStrip = ({
	folder,
	filterFolder,
	setSearchParams,
	setCurrentAsset,
}) => {
	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);

	const renderSliderArrow = (dir, ref) => {
		return (
			<Grid
				container
				width={32}
				alignItems={'center'}
				justifyContent={'center'}
				sx={{
					borderRadius: '100%',
					background: '#fff',
					boxShadow: '0px 1px 4px #00000040',
					aspectRatio: 1,
				}}
				ref={ref}
			>
				{dir === 'prev' ? (
					<ArrowBackIosIcon
						sx={{
							height: 16,
							width: 16,
						}}
					/>
				) : (
					<ArrowForwardIosIcon
						sx={{
							height: 16,
							width: 16,
						}}
					/>
				)}
			</Grid>
		);
	};

	const totalFiles = getTotalFilesInFolder(folder);

	const files = getFilesFromFolder(folder).filter(
		(asset) => !asset.hiddenFile,
	);
	const filesGroupedByFolder = files.reduce((acc, file) => {
		const { folderId } = file;
		if (!acc[folderId]) {
			acc[folderId] = [];
		}
		acc[folderId].push(file);

		return acc;
	}, {});

	return (
		<>
			<Grid container gap={2} alignItems={'center'}>
				<Grid container gap={2} alignItems={'center'}>
					<Typography
						variant='h5'
						sx={{ opacity: 0.7, cursor: 'pointer' }}
						onClick={() => {
							const searchParams = new URLSearchParams();

							// Add search parameters
							searchParams.append('view', 'assets');
							searchParams.append(
								'type',
								folder?.name.toLowerCase(),
							);

							setSearchParams(searchParams);
						}}
					>
						{capitalizeFirstLetter(folder?.name)}
					</Typography>
					<CountBadge>{totalFiles} files</CountBadge>
				</Grid>

				{folder?.Asset?.filter((asset) => !asset.hiddenFile).length >
					3 &&
					!filterFolder && (
						<>
							{renderSliderArrow('prev', navigationPrevRef)}
							{renderSliderArrow('next', navigationNextRef)}
						</>
					)}
			</Grid>
			{filterFolder ? (
				Object.keys(filesGroupedByFolder).map((folderId) => (
					<Grid container gap={3} my={3} key={folderId}>
						{folderId !== folder.id && (
							<Typography
								variant='h5'
								gutterBottom
								width={'100%'}
								sx={{ opacity: 0.7, cursor: 'pointer' }}
								fontSize={18}
								mt={5}
							>
								{capitalizeFirstLetter(
									folder?.children.find(
										(child) => child.id === folderId,
									)?.name,
								)}
							</Typography>
						)}
						{filesGroupedByFolder[folderId].map((asset) => (
							<AssetsDisplayCard
								asset={asset}
								key={asset.id}
								setCurrentAsset={setCurrentAsset}
							/>
						))}
					</Grid>
				))
			) : (
				<Box mt={3} mb={7} width={'123%'}>
					<Swiper
						spaceBetween={20}
						slidesPerView={3.7}
						navigation={{
							prevEl: navigationPrevRef.current,
							nextEl: navigationNextRef.current,
						}}
						modules={[Navigation]}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl =
								navigationPrevRef.current;
							swiper.params.navigation.nextEl =
								navigationNextRef.current;
						}}
						style={{ zIndex: 0 }}
						speed={3500}
					>
						{files.map((asset) => (
							<SwiperSlide
								key={asset?.id}
								style={{ maxWidth: 'fit-content' }}
							>
								<AssetsDisplayCard
									asset={asset}
									key={asset?.id}
									setCurrentAsset={setCurrentAsset}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</Box>
			)}
		</>
	);
};

AssetsStrip.propTypes = {
	setCurrentAsset: PropTypes.func,

	filterFolder: PropTypes.string,
	folder: PropTypes.object,
	setSearchParams: PropTypes.func,
};

export default AssetsStrip;
