import {
	Box,
	Button,
	Grid,
	Step,
	StepButton,
	Stepper,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import { useFileContext } from '../../contexts/FilesContext';
import { useEffect, useRef, useState } from 'react';
import ContentEditable from '../Inputs/ContentEditable';

import palette from '../../styles/palette';
import PropTypes from 'prop-types';
import getCroppedImg, { readFileAsDataURL } from '../../utils/cropImage';
import { getImageSource, isImageFile } from '../../utils/globals';
import ReactQuill from 'react-quill';
import CropperDialog from '../Dialogs/CropperDialog';
import GeneralButton from '../Inputs/GeneralButton';
import { getCurrentThumbnailObj } from '../../utils/fields';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import StepperTags from './StepperTags';
import StepperFiles from './StepperFiles';
import StepperDetails from './StepperDetails';
import StepperThumbnail from './StepperThumbnail';

const steps = ['Details', 'Thumbnail', 'Tags', 'Files'];
// const steps = ['Details', 'Tags', 'Files'];

const DrawerStepper = ({ excludeStep }) => {
	const [activeStep, setActiveStep] = useState(0);

	function a11yProps(index) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	// on edit i think crop isn't being saved correctly and in db thumbnail isn't deleted, another one is being added
	return (
		<>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={activeStep}
					onChange={(event, newValue) => {
						setActiveStep(newValue);
					}}
					aria-label='basic tabs example'
				>
					{steps.map((label, index) => (
						<Tab key={label} label={label} {...a11yProps(index)} />
					))}
				</Tabs>
			</Box>
			{activeStep === 0 && <StepperDetails />}
			{/* THUMBNAIL */}
			{activeStep === 1 && <StepperThumbnail />}
			{activeStep === 2 && <StepperTags />}
			{activeStep === 3 && <StepperFiles />}
		</>
	);
};

DrawerStepper.propTypes = {
	excludeStep: PropTypes.number,
};

export default DrawerStepper;
