import {
	Collapse,
	Grid,
	ImageList,
	List,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import BasicDialog from '../Layouts/BasicDialog';
import PropTypes from 'prop-types';
import ExpandLess from '@mui/icons-material/ExpandLess';
import PlaceholderImage from '../../assets/images/placeholder.jpg';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { Fragment, useEffect, useState } from 'react';
import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import ImageCropper from '../Inputs/ImageCropper';
import { useAlert, ALERT_STATUS } from '../../contexts/AlertContext';
import useGetFoldersByBrand from '../../api/hooks/files/useGetFoldersByBrand';
import GroupToggleButtons from '../Inputs/GroupToggleButtons';
import { getImageSource } from '../../utils/globals';
import { ASSET_TYPES } from '../../utils/enums';
import ImageListAssetCard from '../Cards/ImageListAssetCard';
import { useFields } from '../../contexts/FieldsContext';

const STEPS = {
	CHOOSE: 'CHOOSE',
	CROP: 'CROP',
};
const RATIOS = [
	{ value: 1, label: '1:1' },
	{ value: 4 / 3, label: '4:3' },
	{ value: 3 / 4, label: '3:4' },
	{ value: 16 / 9, label: '16:9' },
	{ value: 9 / 16, label: '9:16' },
];

const AssetSelectDialog = ({ isOpen, setIsOpen }) => {
	const { setBannerField, setIsCreating, setIsEditing, currentBrand } =
		useBrandbooksContext();
	const { currentField, handleSaveField, setCurrentField } = useFields();
	const { handleAlert } = useAlert();
	const [step, setStep] = useState(STEPS.CHOOSE);
	const [selectedAsset, setSelectedAsset] = useState(null);
	const [folders, setFolders] = useState([]);
	const [assets, setAssets] = useState([]);
	const [open, setOpen] = useState(null);
	const [subOpen, setSubOpen] = useState(null);
	const [aspectRatio, setAspectRatio] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(false);
	const { data } = useGetFoldersByBrand(currentBrand?.id);

	const handleClose = () => {
		setIsOpen(false);
		setStep(STEPS.CHOOSE);
		setOpen(null);
		setSelectedAsset(null);
		setAssets([]);
		setCroppedAreaPixels(false);
		setAspectRatio(1);
	};

	const handleContinue = () => {
		if (
			selectedAsset?.type === ASSET_TYPES.COLOR ||
			(selectedAsset?.mimetype &&
				selectedAsset?.mimetype?.includes('gif'))
		) {
			setCurrentField((prev) => ({ ...prev, asset: selectedAsset }));
			handleSaveField({ ...currentField, asset: selectedAsset });
			setIsCreating(false);
			setIsEditing(false);
			handleClose();
			return;
		}
		if (currentField === null) {
			setBannerField((prev) => ({ ...prev, Asset: selectedAsset }));
			setIsOpen(false);
			return;
		}
		if (selectedAsset == null)
			return handleAlert(
				ALERT_STATUS.WARNING,
				'You must select an asset.',
			);
		setStep(STEPS.CROP);
	};

	const onDone = () => {
		const fieldData = {
			...selectedAsset,
			crop: croppedAreaPixels,
		};

		setCurrentField((prev) => ({ ...prev, asset: fieldData }));
		handleSaveField({ ...currentField, asset: fieldData });
		setIsCreating(false);
		setIsEditing(false);
		handleClose();
	};

	const actions = [
		{
			actionHandler: handleClose,
			isMain: false,
		},
		{
			actionHandler:
				step === STEPS.CHOOSE
					? handleContinue
					: step === STEPS.CROP
					? onDone
					: () => {
							//whats happening here?
					  },
			isMain: true,
			title:
				step === STEPS.CHOOSE
					? 'Continue'
					: step === STEPS.CROP
					? 'Done'
					: null,
		},
	];

	useEffect(() => {
		if (data) setFolders(data?.data);
	}, [data, currentBrand]);

	return (
		<BasicDialog
			actions={actions}
			handleClose={handleClose}
			isError={false}
			isOpen={isOpen}
			title={'Select Asset'}
			setIsOpen={setIsOpen}
			maxWidth={900}
		>
			<Grid container sx={{ maxHeight: '60vh' }}>
				{step === STEPS.CHOOSE && (
					<Grid container>
						<Grid item xs={3}>
							<List>
								{folders
									.filter((f) => f?.Asset?.length > 0)
									.map((folder) => (
										<Fragment key={folder?.id}>
											<ListItemButton
												onClick={() => {
													setSubOpen(null);
													setOpen(
														open === folder?.id
															? null
															: folder?.id,
													);
													setAssets(
														folder.Asset.map(
															(asset) => asset,
														),
													);
												}}
												sx={{
													color:
														open === folder?.id
															? 'primary.main'
															: 'inherit',
													textDecoration:
														open === folder?.id
															? 'underline'
															: 'none',
												}}
											>
												{open === folder?.id ? (
													<ExpandLess />
												) : (
													<KeyboardArrowRightOutlinedIcon
														sx={{
															color: 'background.grey',
														}}
													/>
												)}

												<ListItemText
													primary={folder?.name}
													sx={{
														marginInlineStart:
															'12px',
													}}
												/>
											</ListItemButton>
											{folder?.children &&
												folder?.children.length > 0 && (
													<Collapse
														in={open === folder?.id}
														timeout='auto'
														unmountOnExit
													>
														<List
															component='div'
															disablePadding
														>
															{folder?.children
																.filter(
																	(ff) =>
																		ff.Asset
																			?.length >
																		0,
																)
																.map((ff) => {
																	return (
																		<ListItemButton
																			key={
																				ff.id
																			}
																			onClick={() => {
																				setSubOpen(
																					subOpen ===
																						ff?.id
																						? null
																						: ff?.id,
																				);
																				setAssets(
																					ff.Asset.map(
																						(
																							asset,
																						) =>
																							asset,
																					),
																				);
																			}}
																			sx={{
																				marginInlineStart: 3,
																				color:
																					subOpen ===
																					ff?.id
																						? 'primary.main'
																						: 'inherit',
																				textDecoration:
																					subOpen ===
																					ff?.id
																						? 'underline'
																						: 'none',
																			}}
																		>
																			<ListItemText
																				primary={
																					ff?.name
																				}
																				sx={{
																					marginInlineStart:
																						'12px',
																				}}
																			/>
																		</ListItemButton>
																	);
																})}
														</List>
													</Collapse>
												)}
										</Fragment>
									))}
							</List>
						</Grid>
						<Grid item xs={9} justifyContent={'center'}>
							{assets.length > 0 ? (
								<ImageList
									cols={4}
									sx={{ overflow: 'scroll', width: '100%' }}
									rowHeight={115}
									gap={8}
								>
									{assets.map((asset) => (
										<ImageListAssetCard
											key={asset?.id}
											asset={asset}
											selectedAsset={selectedAsset}
											setSelectedAsset={setSelectedAsset}
										/>
									))}
								</ImageList>
							) : (
								<Typography>
									No assets in this folder
								</Typography>
							)}
						</Grid>
					</Grid>
				)}
				{step === STEPS.CROP && (
					<Grid container direction={'column'} gap={2} wrap='nowrap'>
						<Grid container alignItems={'center'} gap={2}>
							<Typography>Aspect ratio</Typography>
							<GroupToggleButtons
								buttons={RATIOS}
								value={aspectRatio}
								selectAction={setAspectRatio}
							/>
						</Grid>

						<Grid
							container
							sx={{
								minHeight: '50vh',
								height: '100%',
								position: 'relative',
							}}
						>
							<ImageCropper
								imageUrl={
									getImageSource(selectedAsset)?.src ||
									PlaceholderImage
								}
								aspect={aspectRatio}
								onCropComplete={(
									croppedArea,
									croppedAreaPixels,
								) => {
									setCroppedAreaPixels(croppedAreaPixels);
								}}
								imgHeight={selectedAsset?.height}
								imgWidth={selectedAsset?.width}
							/>
						</Grid>
					</Grid>
				)}
			</Grid>
		</BasicDialog>
	);
};

AssetSelectDialog.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
};
export default AssetSelectDialog;
