import {
	Box,
	FormControlLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Slide,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import JSZip from 'jszip';
import {
	getFileExtension,
	getImageSource,
	handleImgToDisplay,
} from '../../utils/globals';
import GeneralButton from '../Inputs/GeneralButton';
import useDownloadAsset from '../../api/hooks/files/useDownloadAsset';
import { ReactComponent as Clip } from '../../assets/images/clip.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import palette from '../../styles/palette';
import AssetDetails from '../Lists/AssetDetails';
import { ASSET_TYPES, IMAGE_PREFIX } from '../../utils/enums';
import ColorValues from '../Lists/ColorValues';
import { ReactComponent as CustomCheck } from '../../assets/images/checkbox.svg';

const BrandBookAssetDrawer = ({ asset, setCurrentAsset, language }) => {
	const [linkToDownload, setLinkToDownload] = useState(0);
	const [imageSource, setImageSource] = useState(null);

	const handleSetImageSource = async () => {
		setImageSource(await handleImgToDisplay(asset, IMAGE_PREFIX.MIN));
	};

	const { handleDownloadAsset, isPending } = useDownloadAsset(
		async (data) => {
			// const blob = new Blob([data.data.file?.data]);
			const filesArray = data.data;
			const zip = new JSZip();

			filesArray.forEach((fileObj) => {
				const fileData = fileObj.file?.data;
				if (fileData) {
					const uint8Array = new Uint8Array(fileData);
					zip.file(fileObj.name, uint8Array);
				}
			});
			const blob = await zip.generateAsync({ type: 'blob' });
			const downloadUrl = URL.createObjectURL(blob);
			const downloadButton = document.createElement('a');
			downloadButton.setAttribute('href', downloadUrl);
			downloadButton.setAttribute(
				'download',
				asset?.name.split('.')[0] + '.zip',
			);
			downloadButton.click();

			URL.revokeObjectURL(downloadUrl);
		},
	);

	useEffect(() => {
		// Disable scrolling
		document.body.style.overflow = 'hidden';

		// Cleanup function to enable scrolling when component unmounts
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, []);

	useEffect(() => {
		if (asset && asset?.type !== ASSET_TYPES.COLOR) {
			handleSetImageSource();
		}
	}, [asset]);

	return (
		<Box
			sx={{ position: 'fixed', right: 0, width: '100%', height: '100%' }}
		>
			<Box
				sx={{
					position: 'absolute',
					background: '#000',
					opacity: 0.2,
					inset: 0,
				}}
				onClick={() => {
					setCurrentAsset(null);
				}}
			></Box>
			<Slide direction='left' in={asset} mountOnEnter unmountOnExit>
				<Box
					sx={{
						background: '#fff',
						position: 'absolute',
						right: 0,
						width: '30vw',
						zIndex: 1,
					}}
				>
					<Grid
						container
						direction={'column'}
						p={4}
						gap={2}
						wrap='nowrap'
						sx={{
							height: '71vh',
							overflowY: 'scroll',
						}}
					>
						<Typography sx={{ opacity: 0.7 }}>
							{asset?.name}
						</Typography>
						<Box
							sx={{
								width: '100%',
								aspectRatio: 1.8,
								backgroundColor:
									asset?.type === ASSET_TYPES.COLOR
										? '#' + asset.localPath
										: 'transparent',
							}}
						>
							{asset?.type !== ASSET_TYPES.COLOR && (
								<img
									src={
										imageSource &&
										imageSource?.src.replace(/\\/g, '/')
									}
									alt={asset?.name}
									style={{
										aspectRatio: 1.8,
										objectFit: 'contain',
										width: '100%',
									}}
								/>
							)}
						</Box>
						{asset?.size && <AssetDetails asset={asset} />}
						<Typography
							sx={{ opacity: 0.7 }}
							mt={1}
							textAlign={language === 'ENG' ? 'left' : 'right'}
						>
							{asset?.type === ASSET_TYPES.COLOR ? (
								<ColorValues
									values={JSON.parse([asset?.description])}
								/>
							) : (
								<div
									dangerouslySetInnerHTML={{
										__html: asset?.description,
									}}
								></div>
							)}
						</Typography>
						{asset?.File.length > 0 && (
							<Grid container gap={1} mt={'auto'} color={'#000'}>
								<RadioGroup
									row
									value={linkToDownload}
									onChange={(e) => {
										setLinkToDownload(e.target.value);
									}}
								>
									<FormControlLabel
										value={0}
										control={
											<Radio
												disableRipple
												icon={<CustomCheck />}
												checkedIcon={
													<CustomCheck
														style={{
															fill: palette
																.primary.main,
														}}
													/>
												}
											/>
										}
										label={'All'}
									/>
									<FormControlLabel
										value={asset?.id}
										control={
											<Radio
												disableRipple
												icon={<CustomCheck />}
												checkedIcon={
													<CustomCheck
														style={{
															fill: palette
																.primary.main,
														}}
													/>
												}
											/>
										}
										label={getFileExtension(asset.name)}
									/>
									{asset?.File.map((file) => (
										<FormControlLabel
											key={file?.id}
											value={file?.id}
											control={
												<Radio
													disableRipple
													icon={<CustomCheck />}
													checkedIcon={
														<CustomCheck
															style={{
																fill: palette
																	.primary
																	.main,
															}}
														/>
													}
												/>
											}
											label={getFileExtension(file.name)}
										/>
									))}
								</RadioGroup>
							</Grid>
						)}
						{(!asset.disableDownload ||
							asset?.type === ASSET_TYPES.COLOR) && (
							<GeneralButton
								sx={{
									width: 'fit-content',
								}}
								icon={<Clip />}
								onClick={() => {
									handleDownloadAsset({
										fileToDownloadId: linkToDownload,
										assetId: asset?.id,
									});
								}}
							>
								Download
							</GeneralButton>
						)}
					</Grid>
					<IconButton
						disableRipple
						aria-label='close'
						onClick={() => {
							setCurrentAsset(null);
						}}
						sx={{
							position: 'absolute',
							height: 69,
							aspectRatio: 1,
							left: 0,
							background: '#fff',
							top: '50%',
							transform: 'translate(-50%,-50%)',
							justifyContent: 'start',
							color: '#000',
							zIndex: -1,
							'&:hover': {
								background: '#fff',
								color: palette.primary.main,
							},
						}}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			</Slide>
		</Box>
	);
};
BrandBookAssetDrawer.propTypes = {
	asset: PropTypes.object,
	setCurrentAsset: PropTypes.func,
	language: PropTypes.string,
};
export default BrandBookAssetDrawer;
