import { getImageProxy } from '../api/files';

import { GLOBAL_URL } from '../api/global';

import { ALERT_STATUS } from '../contexts/AlertContext';
import { FieldType } from '../contexts/FieldsContext';
import { ASSET_TYPES, IMAGE_PREFIX } from './enums';
import { getImageSource, handleImgToDisplay } from './globals';

const createImage = async (url) => {
	let imageUrl = url.startsWith('data:')
		? url
		: `${GLOBAL_URL}assets/getImageProxy?url=${url}`;

	return await new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous');
		image.src = imageUrl;
	});
};

function getRadianAngle(degreeValue) {
	return (degreeValue * Math.PI) / 180;
}

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
	const image = await createImage(imageSrc);

	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const maxSize = Math.max(image.width);
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

	canvas.width = safeArea;
	canvas.height = safeArea;

	ctx.translate(safeArea / 2, safeArea / 2);
	ctx.rotate(getRadianAngle(rotation));
	ctx.translate(-safeArea / 2, -safeArea / 2);

	ctx.drawImage(
		image,
		safeArea / 2 - image.width * 0.5,
		safeArea / 2 - image.height * 0.5,
	);
	const data = ctx.getImageData(0, 0, safeArea, safeArea);

	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;

	ctx.putImageData(
		data,
		Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
		Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
	);

	return await new Promise((resolve) => {
		canvas.toBlob((file) => {
			resolve(URL.createObjectURL(file));
		}, 'image/jpeg');
	});
}

export const readFileAsDataURL = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result);
		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
};

export const handleCroppedImage = async (field, handleAlert, setLoading) => {
	setLoading(true);

	try {
		if (
			field?.asset?.type === ASSET_TYPES.COLOR ||
			(field?.asset?.mimetype && field?.asset?.mimetype?.includes('gif'))
		)
			return setLoading(false);

		const croppedImage = await getCroppedImg(
			(await handleImgToDisplay(
				field?.asset,
				field?.type === FieldType.IMAGE
					? IMAGE_PREFIX.MIN
					: IMAGE_PREFIX.CARD,
			)?.src) || getImageSource(field?.asset)?.src,
			field?.asset?.crop,
		);

		setLoading(false);
		return croppedImage;
	} catch (error) {
		handleAlert(
			ALERT_STATUS.ERROR,
			'There was an error with cropping this image.',
		);
		return null;
	}
};
