import { useQuery } from '@tanstack/react-query';

import { getAllBrandbooks } from '../../brandbooks';

const useGetAllBrandBooks = (filter = '') => {
	const QUERY_KEY = ['getAllBrandbooks', filter];

	const { data, isLoading, isError, error, refetch } = useQuery({
		queryKey: QUERY_KEY,
		queryFn: () => getAllBrandbooks(filter),
	});

	return { data, isLoading, isError, error, refetch };
};

export default useGetAllBrandBooks;
