import { useBrandbooksContext } from '../../contexts/BrandbooksContext';
import { GLOBAL_URL } from '../../api/global';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import getCroppedImg from '../../utils/cropImage';
import BlockActions from '../Inputs/BlockActions';
import ImageCropper from '../Inputs/ImageCropper';
import PlaceholderImage from '../../assets/images/placeholder.jpg';
import { getImageSource, handleImgToDisplay } from '../../utils/globals';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import { useFields } from '../../contexts/FieldsContext';
import { Box, Skeleton } from '@mui/material';
import BrandLoader from '../General/BrandLoader';
import { IMAGE_PREFIX } from '../../utils/enums';

const BannerBrandbook = ({ setIsChoosingAsset }) => {
	const { bannerField, setBannerField, setIsCreating, setIsEditing } =
		useBrandbooksContext();
	const { currentField, handleSaveField, setCurrentField } = useFields();
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(
		bannerField?.crop?.width
			? bannerField?.crop
			: {
					width: bannerField?.Asset?.width,
					height: bannerField?.Asset?.height,
					x: 0,
					y: 0,
			  },
	);
	const [showCropper, setShowCropper] = useState(false);
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	const { handleAlert } = useAlert();

	const [loading, setLoading] = useState(false);

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const handleEditClick = () => {
		currentField !== null && handleSaveField(currentField);
		setIsCreating(false);
		setIsEditing(false);
		setCurrentField(null);

		setLoading(true);

		if (croppedImageUrl === null) {
			setBannerField({});
			setIsChoosingAsset(true);
		} else {
			setShowCropper(true);
		}
		setLoading(false);
	};

	const handleSaveImage = async () => {
		setLoading(true);
		setBannerField((prev) => ({
			...prev,
			crop: croppedAreaPixels,
		}));

		setShowCropper(false);
		setLoading(false);
	};

	const handleNewImage = async () => {
		setIsChoosingAsset(true);
	};

	const handleGetCroppedImage = async () => {
		const crop = bannerField?.crop?.width
			? bannerField?.crop
			: {
					width: bannerField?.Asset?.width,
					height: bannerField?.Asset?.height,
					x: 0,
					y: 0,
			  };
		try {
			if (
				bannerField?.Asset?.localPath ||
				bannerField?.Asset?.externalLink
			) {
				setLoading(true);
				const imageSource = await handleImgToDisplay(
					bannerField?.Asset,
					IMAGE_PREFIX.MIN,
				);

				const croppedImage = await getCroppedImg(
					imageSource?.src,
					crop,
				);

				setCroppedImageUrl(croppedImage);
				setLoading(false);
			}
		} catch (error) {
			console.log(error);
			handleAlert(ALERT_STATUS.ERROR, 'Error getting cropped banner.');
		}
	};

	useEffect(() => {
		handleGetCroppedImage();
	}, [bannerField]);

	return (
		<BlockActions
			editAction={handleEditClick}
			saveAction={() => {
				handleSaveImage();
			}}
			switchAction={handleNewImage}
			isEditing={showCropper}
			sx={{
				maxHeight: '26vh',
				width: '100%',
				height: '100%',
			}}
		>
			{loading && (
				<Box
					height={'26vh'}
					width={'100%'}
					display={'flex'}
					alignItems={'center'}
					justifyContent={'center'}
					sx={{
						position: 'absolute',
						inset: 0,
						transform: 'unset',
						zIndex: 1,
						opacity: 0.8,
						backdropFilter: 'blur(5px)',
						background: 'white',
					}}
				>
					<BrandLoader />
				</Box>
			)}
			{showCropper && !loading ? (
				<ImageCropper
					aspect={5.1}
					onCropComplete={onCropComplete}
					imageUrl={getImageSource(bannerField?.Asset)?.src}
					objectFit={'cover'}
				/>
			) : (
				<img
					src={croppedImageUrl || PlaceholderImage}
					alt={bannerField ? bannerField?.Asset?.name : 'Placeholder'}
					style={{
						maxHeight: '26vh',
						width: '100%',
						height: '100%',
						objectFit: croppedImageUrl ? 'cover' : 'contain',
						backgroundColor: '#CCCCCC',
					}}
				/>
			)}
		</BlockActions>
	);
};
BannerBrandbook.propTypes = {
	setIsChoosingAsset: PropTypes.func,
};
export default BannerBrandbook;
