import {
	Box,
	Grid,
	InputAdornment,
	Skeleton,
	TextField,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import getCroppedImg from '../../utils/cropImage';
import PlaceholderImage from '../../assets/images/placeholder.jpg';
import SearchIcon from '../../assets/images/search.png';
import { getImageSource, handleImgToDisplay } from '../../utils/globals';
import { useSearchParams } from 'react-router-dom';
import { IMAGE_PREFIX } from '../../utils/enums';

const DisplayBanner = ({
	banner,
	crop,
	logo,
	setSearchTerm,
	brandName,
	searchTerm,
}) => {
	const [croppedImageUrl, setCroppedImageUrl] = useState('');
	const [searchParams, setSearchParams] = useSearchParams();

	const [loading, setLoading] = useState(true);

	const handleSetBannerCrop = async () => {
		if (!banner) return;

		const imageSource = await handleImgToDisplay(banner, IMAGE_PREFIX.MIN);
		const croppedImage = crop?.width
			? await getCroppedImg(imageSource?.src, crop)
			: getImageSource(banner)?.src;

		setCroppedImageUrl(croppedImage);
		setLoading(false);
	};

	useEffect(() => {
		handleSetBannerCrop();
	}, [banner, crop]);

	return (
		<Grid
			container
			direction={'column'}
			sx={{ position: 'relative', background: '#F8FAFC' }}
			wrap='nowrap'
		>
			<Box sx={{ height: '22vh' }}>
				{loading ? (
					<Skeleton
						height={'22vh'}
						width={'100%'}
						sx={{ transform: 'unset' }}
					/>
				) : (
					<img
						src={croppedImageUrl || PlaceholderImage}
						alt={brandName || 'Placeholder'}
						style={{
							width: '100%',
							height: '100%',
							objectFit: croppedImageUrl ? 'cover' : 'contain',
							backgroundColor: '#CCCCCC',
						}}
					/>
				)}
			</Box>
			<Box
				sx={{ borderBottom: '1px solid #84848454', background: '#fff' }}
				px={'33px'}
				py={'9px'}
			>
				<Grid
					container
					justifyContent={'space-between'}
					ml={'auto'}
					sx={{ width: 'min(1455px, 100%)' }}
					alignItems={'end'}
				>
					<Typography
						color={'#848484'}
						fontSize={18}
						textTransform={'uppercase'}
						fontWeight={500}
					>
						{brandName} brand guidelines
					</Typography>
					<Grid
						container
						gap={1}
						height={48}
						sx={{
							border: '1px solid #84848454',
							borderRadius: '8px',
							width: '400px',
						}}
						alignItems={'center'}
						px={2}
					>
						<img
							src={SearchIcon}
							alt={'Search'}
							style={{ maxHeight: 24 }}
						/>
						<input
							type='text'
							placeholder='Search'
							className='brandbookSearch'
							style={{
								border: 'unset',
								background: 'unset',
								outline: 'unset',
								color: '#848484',
							}}
							onKeyDown={(e) => {
								if (e.key === 'Enter') {
									setSearchTerm(e.target.value);
									const searchParams = new URLSearchParams();

									// Add search parameters
									searchParams.append('view', 'search');

									setSearchParams(searchParams);
								}
							}}
						/>
					</Grid>
				</Grid>
			</Box>
			<Grid
				container
				justifyContent={'center'}
				alignItems={'center'}
				sx={{
					position: 'absolute',
					background: '#fff',
					borderRadius: '30px',
					width: 165,
					aspectRatio: '1',
					left: '4.5%',
					bottom: '18px',
					boxShadow: '0px 4px 4px #00000040',
					overflow: 'hidden',
				}}
				// px={3}
				// py={2}
			>
				<img
					src={getImageSource(logo)?.src}
					alt=''
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
					}}
				/>
			</Grid>
		</Grid>
	);
};
DisplayBanner.propTypes = {
	banner: PropTypes.object,
	crop: PropTypes.object,
	logo: PropTypes.object,
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	brandName: PropTypes.string,
};
export default DisplayBanner;
