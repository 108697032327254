import CardContent from '@mui/material/CardContent';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
	CardMedia,
	Grid,
	Link,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CustomAvatar from './CustomAvatar';
import ActionBtn from './ActionBtn';
import { StyledBrandCard } from '../../styles/layouts';
import { getImageSource } from '../../utils/globals';
import { useState } from 'react';
import { DISPLAY } from '../../contexts/DisplayContext';

const BrandCard = ({ brand, editAction, deleteAction, pendingDelete }) => {
	const { name, featuredImage, logo, Brandbook, id } = brand;

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<StyledBrandCard>
			<CardMedia
				image={getImageSource(featuredImage)?.src.replace(/\\/g, '/')}
				title={name}
				sx={{ opacity: pendingDelete ? 0.7 : 1 }}
			>
				<MoreVertIcon onClick={handleClick} />
				<Menu
					id={`brand-item-menu${brand?.id}`}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
					elevation={0}
					sx={{
						'& .MuiPaper-root': {
							boxShadow: '0 3px 6px #25232342',
							borderRadius: '16px',
						},
					}}
					anchorOrigin={{
						vertical: 0,
						horizontal: 30,
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
				>
					<MenuItem
						onClick={() => {
							editAction();
							handleClose();
						}}
					>
						<ListItemIcon>
							<EditOutlinedIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Edit Brand</ListItemText>
					</MenuItem>
					<MenuItem
						onClick={() => {
							//add delete functionality
							deleteAction(brand?.id);
							handleClose();
						}}
					>
						<ListItemIcon>
							<DeleteOutlinedIcon fontSize='small' />
						</ListItemIcon>
						<ListItemText>Delete Brand</ListItemText>
					</MenuItem>
				</Menu>

				<CustomAvatar
					image={getImageSource(logo)?.src.replace(/\\/g, '/')}
					name={name}
					size={80}
				/>
			</CardMedia>
			<CardContent sx={{ opacity: pendingDelete ? 0.7 : 1 }}>
				<Typography variant='h6' align='center' mt={1}>
					{name}
				</Typography>
				<Grid container justifyContent={'space-evenly'} gap={1}>
					<Link
						component={RouterLink}
						to={'/brandbooks'}
						state={{ brandId: id, display: DISPLAY.BUILDER }}
					>
						<ActionBtn
							text='Brand Book'
							icon={<AutoStoriesOutlinedIcon />}
							sx={{
								opacity: Brandbook?.length ? 1 : 0.5,
								pointerEvent: Brandbook?.length
									? 'all'
									: 'none',
							}}
						/>
					</Link>
					<Link
						component={RouterLink}
						to={'/files'}
						state={{ brandId: id }}
					>
						<ActionBtn
							text='Brand Assets'
							icon={<FolderOutlinedIcon />}
						/>
					</Link>
				</Grid>
				<Typography variant='small' fontSize={14}>
					{`Latest update was on
					${new Date(brand?.updatedAt).toLocaleDateString('he-IL')}`}
				</Typography>
			</CardContent>
		</StyledBrandCard>
	);
};

BrandCard.propTypes = {
	brand: PropTypes.shape({
		name: PropTypes.string.isRequired,
		featuredImage: PropTypes.shape({
			localPath: PropTypes.string.isRequired,
		}),
		logo: PropTypes.shape({
			localPath: PropTypes.string.isRequired,
		}),
		updatedAt: PropTypes.instanceOf(Date),
		Brandbook: PropTypes.array,
		id: PropTypes.string,
	}),
	editAction: PropTypes.func.isRequired,
	deleteAction: PropTypes.func.isRequired,
	pendingDelete: PropTypes.number,
};

export default BrandCard;
