import { Box, Collapse, Grid, lighten, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import palette from '../../styles/palette';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { TransitionGroup } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { useFileContext } from '../../contexts/FilesContext';

const StepperFiles = () => {
	const { currentFile, setCurrentFile } = useFileContext();
	const [relatedFiles, setRelatedFiles] = useState(
		currentFile?.relatedFiles || [],
	);
	const onDrop = (acceptedFiles) => {
		const newFiles = [
			...relatedFiles,
			...acceptedFiles.map((file, index) => ({ ...file, index })),
		]; //whats happening here
		setRelatedFiles((prev) => [...prev, ...acceptedFiles]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	useEffect(() => {
		setCurrentFile((prev) => ({
			...prev,
			relatedFiles: [...relatedFiles],
		}));
	}, [relatedFiles]);

	return (
		<Grid container direction={'column'} gap={1} mt={4}>
			<Box
				{...getRootProps()}
				sx={{
					padding: '2vmin 4vmin',
					border: `2px dashed ${palette.background.grey}`,
					background: lighten(palette.background.grey, 0.7),
					color: palette.text.primary,
					textAlign: 'center',
				}}
			>
				<input {...getInputProps()} />
				{isDragActive ? (
					<p>Drop the files here ...</p>
				) : (
					<p>
						Drag and drop some files here, or click to select files
					</p>
				)}
			</Box>

			<TransitionGroup>
				{[...relatedFiles].map((row, index) => (
					<Collapse key={row.name}>
						<Grid
							container
							justifyContent={'space-between'}
							gap={1}
							wrap='nowrap'
							sx={{
								padding: '6px',
								borderBottom: '1px solid #efefef',
							}}
						>
							<InsertDriveFileIcon />
							<Typography mr={'auto'}>{row.name}</Typography>
							<CloseIcon
								onClick={() => {
									setRelatedFiles((prev) =>
										prev.filter(
											(item, key) => key !== index,
										),
									);
								}}
								sx={{
									color: palette.warning.light,
									cursor: 'pointer',
									alignSelf: 'center',
								}}
							/>
						</Grid>
					</Collapse>
				))}
			</TransitionGroup>
		</Grid>
	);
};

export default StepperFiles;
