import { useState } from 'react';
import { FORM_STEPS, useLogin } from '../../contexts/LoginContext';
import { useUser } from '../../contexts/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import StepLogin from './StepLogin';
import StepAuth from './StepAuth';
import StepForgotPass from './StepForgotPass';
import StepNewPass from './StepNewPass';
import StepHelp from './StepHelp';
import { USER_ROLE } from '../../utils/enums';

const StepSwitch = () => {
	const navigate = useNavigate();
	const { login } = useUser();
	const { brand } = useParams();
	const { currentStep, setIsLoading } = useLogin();

	const [userCred, setUserCred] = useState({ email: '', password: '' });
	const [clientData, setClientData] = useState({});

	//in state do query for user if client exists in param

	const handleNextStep = (user) => {
		login(user);
		setIsLoading(false);

		if (user.role === USER_ROLE.CLIENT) {
			navigate('../brandbook/' + brand);
		} else {
			navigate('/');
		}
	};

	const renderAppropriateStep = () => {
		switch (currentStep) {
			case FORM_STEPS.LOGIN:
				return (
					<StepLogin userCred={userCred} setUserCred={setUserCred} />
				);
			case FORM_STEPS.AUTH_CODE:
				return (
					<StepAuth
						setUserCred={setUserCred}
						userCred={userCred}
						handleNextStep={handleNextStep}
					/>
				);
			case FORM_STEPS.FORGOT_PASS:
				return <StepForgotPass />;
			case FORM_STEPS.NEW_PASS:
				return (
					<StepNewPass
						email={userCred.email}
						handleNextStep={handleNextStep}
					/>
				);
			case FORM_STEPS.HELP:
				return <StepHelp client={clientData} />;
			default:
				return null;
		}
	};
	return renderAppropriateStep();
};

export default StepSwitch;
