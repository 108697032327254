import { Button, Grid } from '@mui/material';
import palette from '../../styles/palette';
import GeneralButton from '../Inputs/GeneralButton';
import { useEffect, useRef, useState } from 'react';
import { useFileContext } from '../../contexts/FilesContext';
import {
	getImageSource,
	handleImgToDisplay,
	isImageFile,
} from '../../utils/globals';
import CropperDialog from '../Dialogs/CropperDialog';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import { getCurrentThumbnailObj } from '../../utils/fields';
import getCroppedImg, { readFileAsDataURL } from '../../utils/cropImage';
import { IMAGE_PREFIX } from '../../utils/enums';
import BrandLoader from '../General/BrandLoader';

const StepperThumbnail = () => {
	const { currentFile, isEditing } = useFileContext();
	const { handleAlert } = useAlert();
	const [thumbnail, setThumbnail] = useState({
		file: null,
		crop: {},
	});
	const [croppedImageUrl, setCroppedImageUrl] = useState(null);
	const [isCropping, setIsCropping] = useState(false);
	const [isUsingCurrent, setIsUsingCurrent] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const thumbRef = useRef(null);

	const isFileImage =
		(Object.hasOwn(currentFile, 'asset') &&
			currentFile?.asset?.type?.includes('image')) ||
		(currentFile?.mimetype && currentFile?.mimetype?.includes('image'));

	const handleCroppedImage = async () => {
		setIsLoading(true);
		try {
			let urlFromFile;
			if (isUsingCurrent && !currentFile?.asset) {
				const thumb = getImageSource(currentFile);
				urlFromFile = thumb?.src;
			} else if (isUsingCurrent && currentFile?.asset) {
				urlFromFile = await readFileAsDataURL(currentFile?.asset);
			} else {
				if (thumbnail?.file instanceof File) {
					urlFromFile = await readFileAsDataURL(thumbnail?.file);
				} else {
					urlFromFile = thumbnail?.file;
				}
			}

			const croppedImage = await getCroppedImg(
				urlFromFile,
				thumbnail?.crop,
			);
			setCroppedImageUrl(croppedImage);
			setIsLoading(false);
		} catch (error) {
			handleAlert(
				ALERT_STATUS.ERROR,
				'There was an error cropping this image.',
			);
		}
	};
	useEffect(() => {
		if (!thumbnail?.file || !thumbnail?.crop?.width) return;
		handleCroppedImage();
	}, [thumbnail]);

	useEffect(() => {
		if (isEditing && !thumbnail?.toDisplay) {
			const image = getCurrentThumbnailObj(currentFile);
			return setThumbnail((prev) => ({
				...prev,
				crop: image.crop,
				file: getImageSource(image)?.src,
			}));
		}
	}, [currentFile]);

	return (
		<>
			<Grid container direction={'column'} gap={1} mt={4}>
				<Grid
					container
					direction={'column'}
					gap={1}
					mb={2}
					overflow={'hidden'}
					height={230}
					width={370}
					borderRadius={4}
					borderBottom={'unset !important'}
					bgcolor={palette.background.brandBook}
					mx={'auto'}
					paddingBottom={'0 !important'}
				>
					{croppedImageUrl && (
						<img
							src={croppedImageUrl}
							alt=''
							style={{
								objectFit: 'cover',
								height: '100%',
								width: '100%',
							}}
						/>
					)}
					{isLoading && <BrandLoader />}
				</Grid>

				<Grid container gap={2}>
					{isFileImage && (
						<GeneralButton
							variant={'outlined'}
							onClick={() => {
								setIsUsingCurrent(true);
								if (Object.hasOwn(currentFile, 'asset')) {
									const reader = new FileReader();
									reader.onload = () => {
										setThumbnail((prev) => ({
											...prev,
											file: currentFile?.asset,
											toDisplay: reader.result,
										}));
										setIsCropping(true);
									};
									reader.readAsDataURL(currentFile?.asset);
								} else {
									setThumbnail((prev) => ({
										...prev,
										file: getImageSource(currentFile)?.src,
									}));
									setIsCropping(true);
								}
							}}
						>
							Crop Image
						</GeneralButton>
					)}

					<input
						ref={thumbRef}
						type='file'
						style={{ display: 'none' }}
						onChange={({ target: { files } }) => {
							setIsUsingCurrent(false);
							const reader = new FileReader();
							reader.onload = () => {
								setThumbnail((prev) => ({
									...prev,
									file: files[0],
									toDisplay: reader.result,
								}));
								setIsCropping(true);
							};
							reader.readAsDataURL(files[0]);

							setIsCropping(true);
						}}
						id={'thumbnail'}
						name={'thumbnail'}
					/>
					<label htmlFor={'thumbnail'}>
						<Button variant='simple' component='span'>
							{isFileImage
								? 'Use different Image'
								: 'Upload Thumbnail'}
						</Button>
					</label>
				</Grid>
			</Grid>
			{isCropping && (
				<CropperDialog
					isOpen={isCropping}
					setIsOpen={setIsCropping}
					thumbnail={thumbnail}
					setThumbnail={setThumbnail}
				/>
			)}
		</>
	);
};

export default StepperThumbnail;
