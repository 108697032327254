export const USER_ROLE = {
	ADMIN: 'ADMIN',
	TEAM: 'TEAM',
	CLIENT: 'CLIENT',
	BRAND: 'BRAND',
};

export const BASE_FOLDERS = {
	LOGOS: 'LOGOS',
	COLORS: 'COLORS',
	FONTS: 'FONTS',
	ICONS: 'ICONS',
	IMAGERY: 'IMAGERY',
	DOCS: 'DOCS',
	GRIDS: 'GRIDS',
	SOCIAL: 'SOCIAL',
};

export const ASSET_TYPES = {
	IMAGE: 'IMAGE',
	COLOR: 'COLOR',
	DOC: 'DOC',
};
export const LANGUAGES = {
	HEB: 'HEB',
	ENG: 'ENG',
};

export const BRANDBOOK_STATUS = {
	DRAFT: 'DRAFT',
	PUBLISH: 'PUBLISH',
	TEMPLATE: 'TEMPLATE',
};

export const IMAGE_PREFIX = {
	MIN: 'min',
	CARD: '370',
	THUMB: '150',
};
