import CardField from '../components/Fields/CardField';

import HeadingField from '../components/Fields/HeadingField';
import ColumnsField from '../components/Fields/ColumnsField';
import ImageField from '../components/Fields/ImageField';
import TextField from '../components/Fields/TextField';
import HeadingDisplay from '../components/Fields/HeadingDisplay';
import TextDisplay from '../components/Fields/TextDisplay';
import ImageDisplay from '../components/Fields/ImageDisplay';
import CardDisplay from '../components/Fields/CardDisplay';
import ColumnsDisplay from '../components/Fields/ColumnsDisplay';
import { FieldType } from '../contexts/FieldsContext';
export const INITIAL_COLUMNS_TOTAL = 4;

export const populateDefaultFieldData = (fieldType) => {
	const defaultObj = {
		type: fieldType,
		value: fieldType === FieldType.COLUMNS ? [] : '',
	};
	switch (fieldType) {
		case FieldType.CARD:
			return {
				...defaultObj,
				title: 'Card Title',
				text: 'Text',
				asset: null,
				hover: {
					text: '',
					asset: null,
				},
				margin: [0, 0, 0, 0],
				padding: [0, 0, 0, 0],
				brandPersonality: null,
			};
		case FieldType.TEXT:
			return {
				...defaultObj,
				margin: [0, 0, 0, 0],
				padding: [0, 0, 0, 0],
				fontSize: 16,
			};
		case FieldType.HEADING:
			return {
				...defaultObj,
				variant: 'h5',
				fontWeight: 700,
				margin: [0, 0, 0, 0],
				padding: [0, 0, 0, 0],
				color: '',
			};
		case FieldType.COLUMNS:
			return {
				...defaultObj,
				total: INITIAL_COLUMNS_TOTAL,
				width: calculatePercentages(INITIAL_COLUMNS_TOTAL),
				margin: [0, 0, 0, 0],
				padding: [0, 0, 0, 0],
				value: Array.from(
					{ length: INITIAL_COLUMNS_TOTAL },
					(_, key) => {
						return null;
					},
				),
			};
		case FieldType.IMAGE:
			return {
				...defaultObj,
				asset: null,
				margin: [0, 0, 0, 0],
				padding: [0, 0, 0, 0],
				borderRadius: [0, 0, 0, 0],
				imageHeight: null,
				imageWidth: parseVminFromPixels(200),
			};
		default:
			return {};
	}
};

export const formatSpacingValues = (valuesArr) => {
	return valuesArr?.join('px ') + 'px';
};

export const parseVminFromPixels = (pixels) => {
	const vmin = Math.min(window.innerWidth, window.innerHeight);
	const vminValue = (pixels / vmin) * 100;
	return vminValue;
};

const calculatePercentages = (x) => {
	const percentages = [];
	const percentageValue = 100 / x;
	for (let i = 0; i < x; i++) {
		percentages.push(percentageToColumns(percentageValue));
	}
	return percentages;
};

export const percentageToColumns = (percentage) => {
	const clampedPercentage = Math.max(0, Math.min(percentage, 100));

	const decimal = clampedPercentage / 100;

	return Math.round(decimal * 12);
};

export const renderComponent = (
	obj,
	setIsChoosingAsset,
	handleIndexingFields,
) => {
	const { type, value, ...props } = obj;
	switch (type) {
		case FieldType.CARD:
			return (
				<CardField
					{...props}
					setIsChoosingAsset={setIsChoosingAsset}
					obj={obj}
				>
					{value}
				</CardField>
			);
		case FieldType.TEXT:
			return <TextField {...props} value={value} obj={obj}></TextField>;
		case FieldType.HEADING:
			return (
				<HeadingField {...props} value={value} obj={obj}>
					{value}
				</HeadingField>
			);
		case FieldType.COLUMNS:
			return (
				<ColumnsField
					{...props}
					value={value}
					setIsChoosingAsset={setIsChoosingAsset}
					handleIndexingFields={handleIndexingFields}
					obj={obj}
				></ColumnsField>
			);
		case FieldType.IMAGE:
			return (
				<ImageField
					{...props}
					setIsChoosingAsset={setIsChoosingAsset}
					obj={obj}
				>
					{value}
				</ImageField>
			);
		default:
			return null; // Or handle unknown types as needed
	}
};

export const renderDisplayComponent = (obj, fieldType, lang) => {
	switch (fieldType) {
		case FieldType.CARD:
			return <CardDisplay field={obj} lang={lang} />;
		case FieldType.TEXT:
			return <TextDisplay field={obj} />;
		case FieldType.HEADING:
			return <HeadingDisplay field={obj} />;
		case FieldType.COLUMNS:
			return <ColumnsDisplay field={obj} />;
		case FieldType.IMAGE:
			return <ImageDisplay field={obj} />;

		default:
			return null; // Or handle unknown types as needed
	}
};

export const getCurrentThumbnailObj = (asset) => {
	const hasThumbnail = asset?.Thumbnail?.length > 0;

	const thumbnailOfOriginal = asset?.Thumbnail?.length
		? asset?.id === asset?.Thumbnail[0]?.id
		: true;

	const sourceForThumbnail = asset?.Thumbnail
		? asset?.Thumbnail[0]?.externalLink
		: null;
	console.log(!hasThumbnail || thumbnailOfOriginal || !sourceForThumbnail);
	if (!hasThumbnail || thumbnailOfOriginal || !sourceForThumbnail)
		return {
			...asset,
			crop: asset?.Thumbnail ? asset?.Thumbnail[0]?.crop : {},
		};
	return asset?.Thumbnail[0];
};

export const handleSetBrandBookFields = (brandbookFields) => {
	const fields = brandbookFields.map((field) => {
		const { id, fieldType, value, order } = field;

		if (fieldType === FieldType.COLUMNS) {
			field.value.value.forEach((item, key) => {
				if (!item) return;
				item.id = key;
				item.index = item?.order || item?.index;
				item.isInColumn = true;
				item.columnIndex = order;
			});
		}

		return {
			id,
			type: fieldType,
			index: order,
			isInColumn: false,
			...value,
		};
	});
	return fields;
};
